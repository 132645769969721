import React from 'react'
import PropTypes from 'prop-types'
import { Flex, SimpleGrid, Box } from '@chakra-ui/layout'
import { motion } from 'framer-motion'
import { InView } from 'react-intersection-observer'

export const ClienteItem = ({ delay, children }) => (
  <InView>
    {({ ref, inView }) => {
      return (
        <Box
          mb={8}
          ref={ref}
          exit={{ x: -100, opacity: 0 }}
          initial={{ x: -100, opacity: 0 }}
          animate={
            inView
              ? {
                  x: 0,
                  opacity: 1,
                  transition: { delay }
                }
              : { x: 100, opacity: 0 }
          }
          w="full"
          maxW="120px"
          height="50px"
          pos="relative"
          as={motion.div}
          display="flex"
          alignItems="center"
          justifySelf="center"
          justifyContent="center"
        >
          {children}
        </Box>
      )
    }}
  </InView>
)

const Clientes = ({ images = [], children, ...props }) => {
  return (
    <Flex
      mx="auto"
      pb={{ xl: 10 }}
      flexDir="column"
      justify="center"
      whiteSpace="normal"
      maxW={{ base: '80%', xl: '1200px' }}
      {...props}
    >
      <SimpleGrid
        alignItems="center"
        justifyContent="center"
        templateColumns={{
          base: 'repeat(2, minmax(0, 1fr))',
          ms: 'repeat(3, minmax(0, 1fr))',
          sm: 'repeat(4, minmax(0, 1fr))',
          md: 'repeat(5, minmax(0, 1fr))',
          lg: 'repeat(6, minmax(0, 1fr))',
          xl: 'repeat(9, minmax(0, 1fr))'
        }}
      >
        {children}
      </SimpleGrid>
    </Flex>
  )
}

Clientes.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
}

export default Clientes
