import React from 'react'
import { StaticImage as Image } from 'gatsby-plugin-image'

import { Box } from '@chakra-ui/layout'
import ClientesSlider from '../Slider/ClientesSlider'
import Clientes, { ClienteItem } from '../../sections/Clientes'

const ClientesMobile = () => {
  return (
    <Box display={{ base: 'block' }}>
      <ClientesSlider
        id="clientes"
        theme="primary"
        heading="Nuestros Clientes"
        sizeHeading={{ base: '2xl', md: '4xl' }}
      >
        {/* PARRILLA UNO */}
        <Clientes>
          <ClienteItem delay={0.1}>
            <Image
              height={50}
              objectFit="cover"
              alt="Logo Adriana"
              src="../../images/logo-adriana.png"
            />
          </ClienteItem>
          <ClienteItem delay={0.1}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-azucar.png"
              alt="Logo azucar"
            />
          </ClienteItem>
          <ClienteItem delay={0.2}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-bivo.png"
              alt="Logo bivo"
            />
          </ClienteItem>
          <ClienteItem delay={0.3}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-chacrabox.png"
              alt="Logo chacrabox"
            />
          </ClienteItem>
          <ClienteItem delay={0.4}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-coccolina.png"
              alt="Logo coccolina"
            />
          </ClienteItem>
          <ClienteItem delay={0.5}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-dumma.png"
              alt="Logo dumma"
            />
          </ClienteItem>
          <ClienteItem delay={0.6}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-joancho.png"
              alt="Logo joancho"
            />
          </ClienteItem>
          <ClienteItem delay={0.7}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-kenido.png"
              alt="Logo kenido"
            />
          </ClienteItem>
          <ClienteItem delay={0.8}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-knn.png"
              alt="Logo knn"
            />
          </ClienteItem>
        </Clientes>
        {/* PARRILLA DOS */}
        <Clientes>
          <ClienteItem delay={0.1}>
            <Image
              height={50}
              alt="Logo laita"
              objectFit="cover"
              src="../../images/logo-laita.png"
            />
          </ClienteItem>
          <ClienteItem delay={0.1}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-lajama.png"
              alt="Logo lajama"
            />
          </ClienteItem>
          <ClienteItem delay={0.2}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-lentes-del-peru.png"
              alt="Logo lentes del peru"
            />
          </ClienteItem>
          <ClienteItem delay={0.3}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-leomi.png"
              alt="Logo leomi"
            />
          </ClienteItem>
          <ClienteItem delay={0.4}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-los-fenicios.png"
              alt="Logo los fenicios"
            />
          </ClienteItem>
          <ClienteItem delay={0.5}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-lucy.png"
              alt="Logo lucy"
            />
          </ClienteItem>
          <ClienteItem delay={0.6}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-mana.png"
              alt="Logo mana"
            />
          </ClienteItem>
          <ClienteItem delay={0.7}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-meat.png"
              alt="Logo meat"
            />
          </ClienteItem>
          <ClienteItem delay={0.8}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-mobble.png"
              alt="Logo mobble"
            />
          </ClienteItem>
        </Clientes>
        {/* PARRILLA TRES */}
        <Clientes>
          <ClienteItem delay={0.1}>
            <Image
              height={50}
              objectFit="cover"
              alt="Logo mon"
              src="../../images/logo-mon.png"
            />
          </ClienteItem>
          <ClienteItem delay={0.1}>
            <Image
              height={50}
              objectFit="contain"
              src="../../images/logo-mora.png"
              alt="Logo mora"
            />
          </ClienteItem>
          <ClienteItem delay={0.2}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-rumbero.png"
              alt="Logo rumbero"
            />
          </ClienteItem>
          <ClienteItem delay={0.3}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-sariana.png"
              alt="Logo sariana"
            />
          </ClienteItem>
          <ClienteItem delay={0.4}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-telaza.png"
              alt="Logo telaza"
            />
          </ClienteItem>
          <ClienteItem delay={0.5}>
            <Image
              height={50}
              objectFit="cover"
              src="../../images/logo-hornitos.png"
              alt="Logo hornitos"
            />
          </ClienteItem>
        </Clientes>
      </ClientesSlider>
    </Box>
  )
}

export default ClientesMobile
