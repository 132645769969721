import * as React from 'react'

import { IconButton } from '@chakra-ui/button'
import { ChevronUpIcon } from '@chakra-ui/icons'
import Link from 'react-scroll/modules/components/Link'

// Components
import Layout from '../components/Layout'
import TestimonioSlider from '../components/Slider'
import BlogSlider from '../components/Slider/BlogSlider'
import ClientesMobile from '../components/ClientesMobile'

// Sections
import Hero from '../sections/Hero'
import Nosotros from '../sections/Nosotros'
import Noticias from '../sections/Noticias'
import Contacto from '../sections/Contacto'
import Productos from '../sections/Productos'
import Servicios from '../sections/Servicios'
import Testimonios from '../sections/Testimonios'
import { useInView } from 'react-intersection-observer'

const Index = () => {
  const { ref, inView } = useInView()

  return (
    <>
      <Layout>
        <div ref={ref}>
          <Hero />
        </div>
        <Nosotros />
        <Productos />
        <Servicios />
        <BlogSlider
          theme="primary"
          sizeHeading={{ base: '2xl', md: '4xl' }}
          heading="Nuestras últimas noticias y artículos"
        >
          <Noticias />
          <Noticias />
          <Noticias />
        </BlogSlider>
        <TestimonioSlider heading="Testimonios">
          <Testimonios />
          <Testimonios />
          <Testimonios />
        </TestimonioSlider>
        <ClientesMobile />
        <Contacto />
      </Layout>

      <IconButton
        spy
        smooth
        as={Link}
        to="inicio"
        pos="fixed"
        zIndex="88"
        fontSize="4xl"
        cursor="pointer"
        colorScheme="secundary"
        left={!inView ? 5 : -1005}
        aria-label="Ir hacia el hero"
        bottom={{ base: 100, xl: 10 }}
        icon={<ChevronUpIcon color="bgPrimary" />}
      />
    </>
  )
}

export default Index
